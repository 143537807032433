<template>
  <div class="home-page">
    <div class="logo">
        <img src="./../assets/logo.png" alt="Logo" />
      </div>
    <div class="blocks">

      <div class="block">
        <i class="fa-solid fa-laptop-code fa-3x"></i>
        <h2>Professionelle Webentwicklung</h2>
        <hr class="underline" />
        <p>Wir helfen Ihnen dabei, eine professionelle und maßgeschneiderte Website zu erstellen, die Ihre Online-Präsenz
          stärkt und Ihr Unternehmen hervorhebt.</p>
      </div>
      <div class="block">
        <i class="fa-solid fa-lightbulb fa-3x"></i>
        <h2>IT-Beratung & Lösungsdesign</h2>
        <hr class="underline" />
        <p>Erhalten Sie maßgeschneiderte IT-Beratung und Lösungen, um Ihre geschäftlichen Anforderungen zu verstehen und
          effektive Strategien zu entwickeln, die Ihr Unternehmen voranbringen.</p>
      </div>
      <div class="block">
        <i class="fa-solid fa-cogs fa-3x"></i>
        <h2>Effiziente Automatisierung</h2>
        <hr class="underline" />
        <p>Entdecken Sie Möglichkeiten zur effizienten Automatisierung von Prozessen, um Zeit zu sparen und Abläufe zu
          optimieren, damit Sie sich auf das Wesentliche konzentrieren können.</p>
      </div>
    </div>
    <div class="footer">
      <p>Kontakt: <a href="mailto:info@bleuel-it.de">info@bleuel-it.de</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
}
</script>

<style scoped>
/* Stile für die Startseite */
.home-page {
  text-align: center;
  width: 95%;
  padding: 20px;
}

.logo img {
  max-width: 95%;
  margin-bottom: 5px;
}



.block {
  max-width: 95%;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
}

.block i {
  color: #333;
  display: block;
  font-size: 3em;
  margin-bottom: 10px;
}

.block h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.block p {
  font-size: 1rem;
  color: #333;
}

.underline {
  border: none;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), #4a5568, rgba(255, 255, 255, 0));
  width: 100%;
  height: 2px;
  margin: 0 auto;
}

.footer {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
.blocks {
 display: flex; 
 justify-content: space-around;
}

.logo img {
  max-width: 40%;
}
}
</style>
